import { Button } from "@/components/ui/button";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Input } from "@/components/ui/input";

import { useForm } from "react-hook-form";
import { sendRequest } from "../services/apiService";
import { useIsAuthenticated } from "@azure/msal-react";
import { CirclePause, File, Image, Plus, Send, X } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Constants } from "@/constants";
import {
  AIMessage,
  LastAIMessage,
  MessageList,
  UserMessage,
} from "@/components/core/messagelist";
import { Card } from "@/components/ui/card";
import { Spinner as ShadcnSpinner } from "@/components/ui/spinner"; // Adjust the import based on your UI library

const Spinner = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center text-white font-light text-2xl bg-black bg-opacity-60 z-50">
      Creating a new thread . . .
      <ShadcnSpinner size="large" className="text-white " />
    </div>
  );
};
export default function AgentHome() {
  const [showLoader, setShowLoader] = useState(false);
  const [assistantShowLoader, assistantSetShowLoader] = useState(false);
  const formRef = useRef();
  const assistantformRef = useRef();
  const textareaRef = useRef(null);
  const assistantTextareaRef = useRef(null);
  const fileInputRef = useRef(null);

  const isAuthenticated = useIsAuthenticated();
  let location = useLocation();
  const [messages, setMessages] = useState([]);
  const [assistantMessages, setAssistantMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [assistantMessage, setAssistantMessage] = useState("");
  const [thread_id, setThreadId] = useState("");
  const messagesEndRef = useRef(null);
  const messagesAssistantEndRef = useRef(null);
  const [showThreadLoader, setShowThreadLoader] = useState(false);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      const newHeight = Math.min(textarea.scrollHeight, 24 * 10); // 24px is roughly one line height, 6 is max rows
      textarea.style.height = `${newHeight}px`;
    }
  }, [message]);
  useEffect(() => {
    const textarea = assistantTextareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      const newHeight = Math.min(textarea.scrollHeight, 24 * 10); // 24px is roughly one line height, 6 is max rows
      textarea.style.height = `${newHeight}px`;
    }
  }, [assistantMessage]);
  useEffect(() => {
    messagesAssistantEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [assistantMessages]);
  var aiMessage = "";
  async function getThreadId() {
    setShowThreadLoader(true);

    const res = await sendRequest(
      null,
      `thread_id?bot_name=${location.state.bot_name}`,
      "get"
    );
    setThreadId(res.data.thread_id);
    setShowThreadLoader(false);
  }
  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };
  const assistanthandleInputChange = (e) => {
    setAssistantMessage(e.target.value);
  };
  function getText(arr) {
    for (const i in arr) {
      let result = JSON.parse(arr[i], { stream: true });
      if (result.type === "text") {
        if (result.text.value) {
          aiMessage += result.text.value;
          setAssistantMessage(aiMessage);
        } else {
          for (const key in result.text.annotations) {
            const element = result.text.annotations[key];
            if (element.type == "file_path") {
              aiMessage = aiMessage.replace(
                element.text,
                `${Constants.apiUrl}/files/${element.file_path.file_id}?bot_name=${location.state.bot_name}`
              );
              setAssistantMessage(aiMessage);
            }
          }
        }
      } else if (result.type === "image_file") {
        aiMessage += `![Image](${Constants.apiUrl}/files/${result.image_file.file_id}?type=image&bot_name=${location.state.bot_name})`;
        setAssistantMessage(aiMessage);
      }
    }
  }
  useEffect(() => {
    getThreadId();
  }, []);

  const handleAssistantSendMessage = () => {
    if (assistantMessage.trim()) {
      assistantformRef.current?.requestSubmit();
      setAssistantMessage("");
    }
  };
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });

  const {
    register: assistantRegister,
    handleSubmit: assistantHandleSubmit,
    setError: assistantSetError,
    formState: { errors: agentErrors, isSubmitting: agentIsSubmitting },
  } = useForm({ mode: "onChange" });
  const [files, setFiles] = useState([]);
  const [assistantFiles, setAssistantFiles] = useState([]);

  const onFileChange = (e) => {
    const filesArray = Array.from(e.target.files || []);
    setFiles(filesArray);
  };
  const onTextareaKeydown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      formRef.current?.requestSubmit();
    }
  };
  const onAssistantTextareaKeydown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      assistantformRef.current?.requestSubmit();
    }
  };
  const { ref, ...rest } = register("message");
  const { ref: assistantRef, ...assistantRest } = assistantRegister("message");

  const { ref: fileRef, ...fileRest } = register("files");
  const onAssistantSubmit = async (data, e) => {
    setFiles([]);
    e.target.reset();
    setAssistantMessage(() => "");
    const messageWithSender = {
      sender: "user",
      message: data.message,
      timestamp: new Date().toISOString(), // Add timestamp here
    };
    assistantSetShowLoader(true);
    setAssistantMessages((prev) => [...prev, messageWithSender]);
    let params = { message: data.message };
    if (files.length > 0) {
      const file = new FormData();
      files.forEach((fileItem) => {
        file.append("files", fileItem, fileItem.name); // Append each file with its name
      });
      file.append("bot_name", location.state.bot_name);

      const resp = await sendRequest(
        file,
        "files",
        "post",
        "multipart/form-data"
      );
      params["other_file_ids"] = resp.data.other_file_ids;
      params["image_ids"] = resp.data.image_ids;
    }
    try {
      const response = await fetch(`${Constants.apiUrl}/streaming`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...params,
          chat_history: messages,
          from_agent: true,
          thread: thread_id,
        }),
      });

      const reader = response.body.getReader();

      const decoder = new TextDecoder("utf-8");

      while (true) {
        const { done, value } = await reader.read();

        if (done) break;
        const val = decoder.decode(value, { stream: true });
        const arr = val.split("\n--END--\n").filter((ele) => ele);
        getText(arr);
      }
      assistantSetShowLoader(false);
      setAssistantMessages((prev) => [
        ...prev,
        {
          sender: "ai",
          message: aiMessage,
          timestamp: new Date().toISOString(),
        },
      ]);
      setAssistantMessage("");
    } catch {
      setAssistantMessages((prev) => [
        ...prev,
        {
          sender: "ai",
          message: aiMessage,
          timestamp: new Date().toISOString(),
        },
      ]);
      assistantSetShowLoader(false);
    }
  };
  const onSubmit = async (data, e) => {
    e.target.reset();
    setMessage(() => "");
    const messageWithSender = {
      sender: "user",
      message: data.message,
      timestamp: new Date().toISOString(), // Add timestamp here
    };
    setShowLoader(true);
    setMessages((prev) => [...prev, messageWithSender]);
    let params = { user_question: data.message, chat_history: messages };
    if (files.length > 0) {
      const file = new FormData();
      files.forEach((fileItem) => {
        file.append("files", fileItem, fileItem.name); // Append each file with its name
      });
      const resp = await sendRequest(
        file,
        "files",
        "post",
        "multipart/form-data"
      );
      params["file_ids"] = resp.data.file_ids;
    }
    try {
      const response = await sendRequest(params, "agent", "post");
      setMessages((prev) => [
        ...prev,
        {
          sender: "ai",
          message: response.data.output,
          timestamp: new Date().toISOString(),
        },
      ]);
    } catch {
      setMessages((prev) => [
        ...prev,
        {
          sender: "ai",
          message: aiMessage,
          timestamp: new Date().toISOString(),
        },
      ]);
    }
    setMessage(() => "");
    setShowLoader(false);
  };

  async function onCancel() {
    await sendRequest(null, `cancel?thread_id=${thread_id}`, "get");
  }
  return (
    <>
      {showThreadLoader && <Spinner />}
      <header className="flex justify-center py-1">
        <div className=" font-light text-2xl text-blue-500">
          {location.state.title}
        </div>
      </header>
      <div className="flex w-full h-full overflow-hidden">
        <div className="flex flex-col flex-1 unybrands-bg-vlight overflow-hidden">
          <MessageList classname={"w-[90%] drop-shadow-md"}>
            {messages.map((msg) => (
              <div
                key={msg.id}
                className={`flex ${
                  msg.sender === "user" ? "justify-end" : "justify-start"
                }`}
              >
                {msg.sender === "user" ? (
                  <UserMessage message={msg} />
                ) : (
                  <AIMessage message={msg} />
                )}
              </div>
            ))}
            {showLoader && (
              <LastAIMessage message={message} showLoader={showLoader} />
            )}
            <div ref={messagesEndRef} />
          </MessageList>
          <footer className="pb-4 drop-shadow-md">
            <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
              <Card className="max-w-[90%] mx-auto bg-zinc-100 border-0 rounded-none rounded-b-lg">
                <div className="flex flex-col p-2 gap-2">
                  {/* {files.length > 0 && (
                    <div className="flex flex-wrap gap-2">
                      {files.map((file) => (
                        <div
                          key={file.id}
                          className="flex items-center bg-zinc-400 text-zinc-800 px-2 py-1"
                        >
                          {file.type === "image" ? (
                            <Image className="h-4 w-4 mr-2" />
                          ) : (
                            <File className="h-4 w-4 mr-2" />
                          )}
                          <span className="text-sm truncate max-w-[150px]">
                            {file.name}
                          </span>
                          <Button
                            variant="ghost"
                            size="icon"
                            className="ml-1 h-5 w-5 text-zinc-400 hover:text-zinc-100"
                            onClick={() => handleRemoveFile(file.id)}
                          >
                            <X className="h-3 w-3" />
                            <span className="sr-only">Remove file</span>
                          </Button>
                        </div>
                      ))}
                    </div>
                  )} */}
                  <div className="flex p-2 gap-2">
                    <textarea
                      ref={(e) => {
                        ref(e);
                        textareaRef.current = e; // you can still assign to ref
                      }}
                      className="flex-1 bg-transparent text-black placeholder-zinc-600 outline-none resize-none overflow-y-auto "
                      onKeyDown={onTextareaKeydown}
                      onInput={handleInputChange}
                      placeholder="Message UB AI"
                      disabled={showLoader}
                      rows={1}
                      {...rest}
                      style={{ minHeight: "24px", maxHeight: "240px" }} // 24px * 6 rows
                    />
                  </div>
                  <Input
                    ref={(e) => {
                      fileRef(e);
                      fileInputRef.current = e; // you can still assign to ref
                    }}
                    multiple
                    {...fileRest}
                    className="text-sm text-grey-500 w-full h-24
              file:mr-5 file:py-2 file:px-6
              file:rounded-full file:border-0
              file:text-sm file:font-medium
              file:bg-blue-50 file:text-blue-700
              hover:file:cursor-pointer hover:file:bg-amber-50
              border-none
              hover:file:text-amber-700 hidden"
                    name="file"
                    type="file"
                    placeholder={"TEXT"}
                    onChange={onFileChange}
                  />

                  <div className="flex justify-between">
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="text-black  hover:bg-zinc-500 rounded-md transition-colors"
                        >
                          <Plus className="h-5 w-5" />
                          <span className="sr-only">Add file or image</span>
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent
                        align="start"
                        className="w-56 bg-zinc-800 text-zinc-100 "
                      >
                        <DropdownMenuItem
                          onClick={() => handleFileUpload("image")}
                          className="hover:bg-zinc-700 focus:bg-zinc-700"
                          disabled
                        >
                          <Image className="mr-2 h-4 w-4" />
                          <span>Upload Image</span>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={() => fileInputRef.current.click()}
                          className="hover:bg-zinc-700 focus:bg-zinc-700"
                          disabled
                        >
                          <File className="mr-2 h-4 w-4" />
                          <span>Upload File</span>
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                    {showLoader ? (
                      <Button
                        variant="ghost"
                        size="icon"
                        className="text-zinc-400 hover:text-black hover:bg-white/10 rounded-md transition-colors"
                        onClick={onCancel}
                      >
                        <CirclePause className="h-5 w-5" />
                      </Button>
                    ) : (
                      <Button
                        variant="ghost"
                        size="icon"
                        className="text-zinc-400 hover:text-black hover:bg-white/10 rounded-md transition-colors"
                        disabled={message.length <= 0}
                      >
                        <Send className="h-5 w-5" />
                        <span className="sr-only">Send message</span>
                      </Button>
                    )}
                  </div>
                </div>
              </Card>
            </form>
          </footer>
        </div>
        <div className="flex flex-col flex-1 unybrands-bg-vlight overflow-hidden">
          <MessageList classname={"w-[90%] drop-shadow-md"}>
            {assistantMessages.map((msg) => (
              <div
                key={msg.id}
                className={`flex ${
                  msg.sender === "user" ? "justify-end" : "justify-start"
                }`}
              >
                {msg.sender === "user" ? (
                  <UserMessage message={msg} />
                ) : (
                  <AIMessage message={msg} />
                )}
              </div>
            ))}
            {assistantShowLoader && (
              <LastAIMessage
                message={assistantMessage}
                showLoader={assistantShowLoader}
              />
            )}
            <div ref={messagesAssistantEndRef} />
          </MessageList>
          <footer className="pb-4 drop-shadow-md">
            <form
              onSubmit={assistantHandleSubmit(onAssistantSubmit)}
              ref={assistantformRef}
            >
              <Card className="max-w-[90%] mx-auto bg-zinc-100 border-0 rounded-none rounded-b-lg">
                <div className="flex flex-col p-2 gap-2">
                  {files.length > 0 && (
                    <div className="flex flex-wrap gap-2">
                      {files.map((file) => (
                        <div
                          key={file.id}
                          className="flex items-center bg-zinc-400 text-zinc-800 rounded px-2 py-1"
                        >
                          {file.type === "image" ? (
                            <Image className="h-4 w-4 mr-2" />
                          ) : (
                            <File className="h-4 w-4 mr-2" />
                          )}
                          <span className="text-sm truncate max-w-[150px]">
                            {file.name}
                          </span>
                          <Button
                            variant="ghost"
                            size="icon"
                            className="ml-1 h-5 w-5 text-zinc-400 hover:text-zinc-100"
                            // onClick={() => handleRemoveFile(file.id)}
                          >
                            <X className="h-3 w-3" />
                            <span className="sr-only">Remove file</span>
                          </Button>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="flex p-2 gap-2">
                    <textarea
                      ref={(e) => {
                        assistantRef(e);
                        assistantTextareaRef.current = e; // you can still assign to ref
                      }}
                      className="flex-1 bg-transparent text-black placeholder-zinc-600 outline-none resize-none overflow-y-auto "
                      onKeyDown={onAssistantTextareaKeydown}
                      onInput={assistanthandleInputChange}
                      placeholder="Message UB AI"
                      disabled={showLoader}
                      rows={1}
                      {...assistantRest}
                      style={{ minHeight: "24px", maxHeight: "240px" }} // 24px * 6 rows
                    />
                  </div>
                  <Input
                    ref={(e) => {
                      fileRef(e);
                      fileInputRef.current = e; // you can still assign to ref
                    }}
                    multiple
                    {...fileRest}
                    className="text-sm text-grey-500 w-full h-24
              file:mr-5 file:py-2 file:px-6
              file:rounded-full file:border-0
              file:text-sm file:font-medium
              file:bg-blue-50 file:text-blue-700
              hover:file:cursor-pointer hover:file:bg-amber-50
              border-none
              hover:file:text-amber-700 hidden"
                    name="file"
                    type="file"
                    placeholder={"TEXT"}
                    onChange={onFileChange}
                  />

                  <div className="flex justify-between">
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="text-black  hover:bg-zinc-500 rounded-md transition-colors"
                        >
                          <Plus className="h-5 w-5" />
                          <span className="sr-only">Add file or image</span>
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent
                        align="start"
                        className="w-56 bg-zinc-800 text-zinc-100 "
                      >
                        <DropdownMenuItem
                          onClick={() => fileInputRef.current.click()}
                          className="hover:bg-zinc-700 focus:bg-zinc-700"
                        >
                          <File className="mr-2 h-4 w-4" />
                          <span>Upload File</span>
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                    {showLoader ? (
                      <Button
                        variant="ghost"
                        size="icon"
                        className="text-zinc-400 hover:text-black hover:bg-white/10 rounded-md transition-colors"
                        onClick={onCancel}
                      >
                        <CirclePause className="h-5 w-5" />
                      </Button>
                    ) : (
                      <Button
                        variant="ghost"
                        size="icon"
                        className="text-zinc-400 hover:text-black hover:bg-white/10 rounded-md transition-colors"
                        onClick={handleAssistantSendMessage}
                        disabled={assistantMessage.length <= 0}
                      >
                        <Send className="h-5 w-5" />
                        <span className="sr-only">Send message</span>
                      </Button>
                    )}
                  </div>
                </div>
              </Card>
            </form>
            {/* <form
              onSubmit={assistantHandleSubmit(onAssistantSubmit)}
              ref={assistantformRef}
            >
              <div className="flex items-center gap-2">
                <Textarea
                  disabled={showLoader}
                  {...assistantRegister("message", {
                    required: {
                      value: true,
                    },
                  })}
                  style={{ maxHeight: "150px" }} // Limits the max height for readability
                  onKeyDown={onAssistantTextareaKeydown}
                  Label="Chat here"
                  className="resize-none focus-visible:ring-0"
                  placeholder="Message to AI Assistant"
                  errors={errors}
                  onInput={(e) => {
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                  }}
                  inputId="message"
                />
                {errors.asin && (
                  <span className="text-destructive text-xs">
                    {errors.asin.message}
                  </span>
                )}
                {!assistantShowLoader && (
                  <Button
                    className="top-10 right-10 w-8 h-8"
                    disabled={assistantShowLoader}
                    size="icon"
                  >
                    <ArrowUpIcon className="w-5 h-5" />
                    <span className="sr-only">Send</span>
                  </Button>
                )}
                {assistantShowLoader && (
                  <Button
                    onClick={() => onCancel()}
                    className="top-10 right-10 w-8 h-8"
                    disabled={!assistantShowLoader}
                    size="icon"
                  >
                    <Pause className="w-5 h-5" />
                    <span className="sr-only">Stop</span>
                  </Button>
                )}
              </div>
              <Input
                multiple
                {...assistantRegister("files")}
                className="text-sm text-grey-500 w-full h-24
                  file:mr-5 file:py-2 file:px-6
                  file:rounded-full file:border-0
                  file:text-sm file:font-medium
                  file:bg-blue-50 file:text-blue-700
                  hover:file:cursor-pointer hover:file:bg-amber-50
                  border-none
                  hover:file:text-amber-700"
                name="file"
                type="file"
                placeholder={"TEXT"}
                onChange={onFileChange}
              />
            </form> */}
          </footer>
        </div>
      </div>
    </>
  );
}

function ArrowUpIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m5 12 7-7 7 7" />
      <path d="M12 19V5" />
    </svg>
  );
}

function BotIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 8V4H8" />
      <rect width="16" height="12" x="4" y="8" rx="2" />
      <path d="M2 14h2" />
      <path d="M20 14h2" />
      <path d="M15 13v2" />
      <path d="M9 13v2" />
    </svg>
  );
}

function CodeIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="16 18 22 12 16 6" />
      <polyline points="8 6 2 12 8 18" />
    </svg>
  );
}

function ImageIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="18" height="18" x="3" y="3" rx="2" ry="2" />
      <circle cx="9" cy="9" r="2" />
      <path d="m21 15-3.086-3.086a2 2 0 0 0-2.828 0L6 21" />
    </svg>
  );
}

function MegaphoneIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m3 11 18-5v12L3 14v-3z" />
      <path d="M11.6 16.8a3 3 0 1 1-5.8-1.6" />
    </svg>
  );
}

function PaletteIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="13.5" cy="6.5" r=".5" fill="currentColor" />
      <circle cx="17.5" cy="10.5" r=".5" fill="currentColor" />
      <circle cx="8.5" cy="7.5" r=".5" fill="currentColor" />
      <circle cx="6.5" cy="12.5" r=".5" fill="currentColor" />
      <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10c.926 0 1.648-.746 1.648-1.688 0-.437-.18-.835-.437-1.125-.29-.289-.438-.652-.438-1.125a1.64 1.64 0 0 1 1.668-1.668h1.996c3.051 0 5.555-2.503 5.555-5.554C21.965 6.012 17.461 2 12 2z" />
    </svg>
  );
}

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

import { useForm } from "react-hook-form";

import { Button } from "@/components/ui/button";

import { sendRequest } from "../services/apiService";
import { subDays } from "date-fns";
import { Spinner as ShadcnSpinner } from "@/components/ui/spinner"; // Adjust the import based on your UI library

const Spinner = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center text-white font-light text-2xl bg-black bg-opacity-60 z-50">
      Submitting Request . . .
      <ShadcnSpinner size="large" className="text-white " />
    </div>
  );
};
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { DatePickerWithRange } from "@/components/includes/daterangepicker";
import { ToastContainer, toast } from "react-toastify";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
const NUMBER_OF_SUGGESTIONS = 5;

const ReviewAnalysisDb = () => {
  const navigate = useNavigate();

  const suggestions_list = [...Array(NUMBER_OF_SUGGESTIONS + 1).keys()]
    .slice(1)
    .map((i) => {
      return { optionName: `${i}`, optionValue: `${i}` };
    });
  const VerifiedList = [
    {
      optionName: "All Reviews",
      optionValue: "-1",
    },
    {
      optionName: "Verified",
      optionValue: "1",
    },
  ];
  const CountryList = [
    {
      optionName: "All Marketplaces",
      optionValue: "-1",
    },
    {
      optionName: "United States",
      optionValue: "US",
    },
    {
      optionName: "United Kingdom",
      optionValue: "UK",
    },
    {
      optionName: "France",
      optionValue: "FR",
    },
    {
      optionName: "Germany",
      optionValue: "DE",
    },
    {
      optionName: "Italy",
      optionValue: "IT",
    },
    {
      optionName: "Australia",
      optionValue: "AU",
    },
    {
      optionName: "Belgium",
      optionValue: "BE",
    },
    {
      optionName: "Brazil",
      optionValue: "BR",
    },
    {
      optionName: "Canada",
      optionValue: "CA",
    },
    {
      optionName: "China",
      optionValue: "CN",
    },
    {
      optionName: "Egypt",
      optionValue: "EG",
    },
    {
      optionName: "India",
      optionValue: "IN",
    },
    {
      optionName: "Japan",
      optionValue: "JP",
    },
    {
      optionName: "Mexico",
      optionValue: "MX",
    },
    {
      optionName: "Netherlands",
      optionValue: "NL",
    },
    {
      optionName: "Poland",
      optionValue: "PL",
    },
    {
      optionName: "Saudi Arabia",
      optionValue: "SA",
    },
    {
      optionName: "Singapore",
      optionValue: "SG",
    },
    {
      optionName: "Spain",
      optionValue: "ES",
    },
    {
      optionName: "Sweden",
      optionValue: "SE",
    },
    {
      optionName: "Turkey",
      optionValue: "TR",
    },
    {
      optionName: "UAE",
      optionValue: "AE",
    },
  ];
  const defaultDate = {
    from: subDays(new Date(), 365),
    to: new Date(),
  };
  const [showLoader, setShowLoader] = useState(false);
  const [verified, setVerified] = useState(-1);
  const [response, setResponse] = useState({});
  const [requestDone, setRequestDone] = useState(false);
  const [requestData, setRequestData] = useState({});
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });
  useEffect(() => {
    setValue("amazon_marketplace", "US");
    setValue("review_stars", "all_stars");
  }, []);

  const onSubmit = async (data) => {
    setShowLoader(true);
    console.log(data.reviewDateRange);
    if (!data.reviewDateRange) {
      data.start_date = defaultDate.from;
      data.end_date = defaultDate.to;
    } else {
      data.start_date = data.reviewDateRange.from;
      data.end_date = data.reviewDateRange.to;
    }
    const requestBody = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== "-1")
    );
    const response = await sendRequest(
      requestBody,
      "customer_review_analysis_db"
    );
    if (response.status === 200) {
      setResponse(response.data);
      setRequestData(requestBody);
      setShowLoader(false);
      setRequestDone(true);
      const heading = `### **Review Analysis (Database)**`;
      const asinString = `### **Asin**: ${requestBody.asin}`;
      const reviewListString = response.data.review_list
        .map(
          (review, index) =>
            `# **${index + 1} Review Title**: ${review[0]}
  
      ${review[1]}
      
      - ${review[2]}
      - ${review[3]}
      - ${review[4].toUpperCase()}
      - ${review[6]}
      - [${review[7]}](${review[7]})
      `
        )
        .join("\n");
      const promptString = `**Prompt**: ${response.data.prompt}`;
      const suggestionsString = response.data.suggestions.join("\n");
      const numberOfReviews = `**Number of reviews:** ${response.data.review_list.length}`;
      const title = `**Title:** ${response.data.title}`;
      const finalString = [
        heading,
        title,
        numberOfReviews,
        asinString,
        reviewListString,
        suggestionsString,
        promptString,
      ].join("\n");

      navigate("/results", {
        state: {
          data: finalString,
          thread_id: response.data.thread_id,
          bot_name: "chat_service",
        },
      });
    }

    setShowLoader(false);
    setRequestDone(false);
    toast.error(
      `Asin/Marketplace combination or reviews do not exist within this date range`,
      {
        position: "top-center",
      }
    );
    // setError("asin", {
    //   type: "custom",
    //   message: "ASIN or reviews do not exist",
    // });
  };

  const StarsList = [
    {
      optionName: "All Stars",
      optionValue: "all_stars",
    },
    {
      optionName: "Five Star",
      optionValue: "five_star",
    },
    {
      optionName: "Four Star",
      optionValue: "four_star",
    },
    {
      optionName: "Three Star",
      optionValue: "three_star",
    },
    {
      optionName: "Two Star",
      optionValue: "two_star",
    },
    {
      optionName: "One Star",
      optionValue: "one_star",
    },
    {
      optionName: "All Positive",
      optionValue: "all_positive",
    },
    {
      optionName: "All Critical",
      optionValue: "all_critical",
    },
  ];
  return (
    <>
      {showLoader && <Spinner />}
      <ToastContainer />

      <Card className="w-full max-w-2xl mx-auto unybrands-bg-vlight overflow-auto border-0">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-primary">
            Review Analyzer form
          </CardTitle>
          <CardDescription>
            Enter the details to fetch and analyze Amazon product reviews
          </CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)} className="text-black">
            <div className=" flex-col  space-y-5">
              <div className="space-y-2 ">
                <Label className="text-black">ASIN</Label>
                <Input
                  {...register("asin", {
                    required: {
                      value: true,
                      message: "Please fill in the ASIN field",
                    },
                    minLength: {
                      value: 10,
                      message: "Please enter a 10-digit ASIN",
                    },
                    maxLength: {
                      value: 10,
                      message: "Please enter a 10-digit ASIN",
                    },
                  })}
                  Label="Enter ASIN #(Required):"
                  placeholder="Enter the exact Amazon ASIN"
                  errors={errors}
                  inputId="asin"
                />
                {errors.asin && (
                  <span className="text-destructive text-xs">
                    {errors.asin.message}
                  </span>
                )}
              </div>
              <div className="space-y-2">
                <Label className="text-black">Marketplace</Label>
                <Select
                  defaultValue="US"
                  inputId="amazon_marketplace"
                  onValueChange={(e) => setValue("amazon_marketplace", e)}
                >
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {CountryList.map((country) => (
                        <SelectItem value={country.optionValue}>
                          {country.optionName}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label className="text-black">Ratings</Label>
                <Select
                  defaultValue="all_stars"
                  inputId="review_stars"
                  onValueChange={(e) => setValue("review_stars", e)}
                >
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {StarsList.map((stars) => (
                        <SelectItem value={stars.optionValue}>
                          {stars.optionName}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label className="text-black">
                  Choose All reviews/Verified
                </Label>
                <Select
                  defaultValue="-1"
                  inputId="review_type"
                  onValueChange={(e) => setValue("review_type", e)}
                >
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {VerifiedList.map((item) => (
                        <SelectItem value={item.optionValue}>
                          {item.optionName}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label className="text-black">Date range</Label>
                <DatePickerWithRange
                  className={"w-full"}
                  defaultDate={defaultDate}
                  setValue={setValue}
                ></DatePickerWithRange>
              </div>
              <div className="space-y-2 ">
                <Label className="text-black">Number of reviews</Label>

                <Input
                  {...register("num_reviews", {
                    required: {
                      value: true,
                      message: "Please fill in number of reviews",
                    },
                  })}
                  Label="Number of reviews"
                  placeholder="10"
                  defaultValue="10"
                  errors={errors}
                  inputId="num_reviews"
                />
                {errors.num_reviews && (
                  <span className="text-destructive text-xs">
                    {errors.num_reviews.message}
                  </span>
                )}
              </div>

              <div className="space-y-2 text-black">
                <Label className="text-black">Prompt</Label>
                <Textarea
                  id="prompt"
                  placeholder="Prompt Instructions"
                  {...register("prompt")}
                  className=""
                />
              </div>
              <div className="p-4 rounded-md">
                <p className="text-sm ">
                  If no specific prompt is given, then you will receive a report
                  with the following information: (A) impression on public, (B)
                  complaints, (C) 3 top positive reviews, (D) 3 top negative
                  reviews, and (E) product improvement suggestions.{" "}
                </p>
              </div>
              <div className="space-y-2">
                <Button className="w-full text-black h-full">Submit</Button>
              </div>
            </div>
          </form>
        </CardContent>
      </Card>
    </>
  );
};

export default ReviewAnalysisDb;
